.div__home{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section__documentos h4{
    color: var(--first-color);
}

.column__documentos{
    max-width: 360px;
}

.section__documentos a{
    color: #000;
}

.section__documentos a:hover{
    color: var(--second-color);
}

@media screen and (max-width: 768px){
    .section__documentos article{
        justify-content: start!important;
    }
}