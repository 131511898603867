.containerMap{
    zoom: 1!important;
}

.containerMap h2{
    color: var(--fifth-color);
    width: 100%;
    text-align: center;
    font-weight: 400!important;
    padding: 1rem 0;
}