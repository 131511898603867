.container__admin{
    padding-bottom: 1rem;
    height: 100vh;
}

.header-space{
    min-height: 66px;
}

.comision__container label, .coachs__container label, .documentos__container label{
    font-weight: 600;
}

.container__documento select{
    padding: 0.5rem;
}

.accordion button:hover{
    /* background-color: var(--fifth-color)!important; */
}

.accordion-item{    
    border: 0!important;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.accordion-button{
    background-color: var(--first-color)!important;
    border: 1px solid lightgray;
}

.accordion-button p{
    color: #FFF;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff0000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
 }

.accordion-button:focus{
    box-shadow: none!important;
}

.show{
    border-top: 1px solid lightgray;
}

.accordionText{
    margin: 0;
    color: var(--dark-color)!important;
    font-weight: 600;
}

.fixture__admin:hover .fixture__img{
    cursor: pointer;
    display: none;
}

.fixture__admin:hover .fixture__img__hover{
    cursor: pointer;
    display: block;
}

.fixture__img, .partner__img{
    background: url("../../media/img/trash_red.png");
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.5);
}

.fixture__img__hover, .partner__img__hover{
    display: none;
    object-fit: contain;
    padding: 1rem;    
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.5);
    filter: drop-shadow(1px 2px 3px #000);
}

.partner__admin:hover .partner__img{
    cursor: pointer;
    display: none;
}

.partner__admin:hover .partner__img__hover{
    cursor: pointer;
    display: block;
}

.img__sponsor__new{
    background-color: #DDD;
}


@media screen and (max-width: 479px){
    .container__documento{
        flex-direction: column;
    }
}