.documento__admin__container{
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.documento__admin__container div{
    width: 50%;
    align-self: center;
    padding: 0 0.5rem;
}

.documento__link{
    color: #000;
}

.documento__link:hover{
    color: var(--second-color);
}