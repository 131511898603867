.banner__video {
    /* display: block!important; */
    width: 100%;
    max-height: 80vh;
    object-fit: cover;
    background: #FFF !important;
}

#bannerVideo {
    display: none;
}

video::-webkit-media-controls {
    display: none !important;
}

/* .item {
    min-height: 75vh;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    background-attachment: fixed;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
}

#banner_img{
    filter: opacity(0.8);
    filter: brightness(65%);
    object-position: top;
}

.fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    padding: 0 !important;
    margin: 0 !important;
    object-fit: cover;
}

.no-click {
    pointer-events: none;
}

.section-bg-overlay {
    max-height: 75vh;
    background-color: rgba(0, 0, 0, 0.0);
}

.banner__caption {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    margin: 0 auto;
    text-align: start;
    align-items: start;
    height: 100%;
    justify-content: center;
    color: var(--light-color);
    height: 100%;
    color: white;
    padding-top: 5%;
}

.banner__caption h4 {
    color: var(--span-second-color);
    text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.8);
    margin-left: 5px;
}

.banner__caption h2 {
    font-size: clamp(2rem, 0.8385rem + 2.7513vw, 4.5rem);
    color: var(--light-color);
    text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.8);
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
}

@media screen and (min-width: 999px) {
    #bannerVideo {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .banner__caption {
        max-width: 70%;
    }

    /* #bannerVideo{
        display: none!important;
        visibility: hidden!important;
        z-index: -100!important;
    } */
}

@media screen and (max-width: 479px) {
    /* .item {
        min-height: 25vh;
        max-height: 25vh;
    } */
    
    .banner__caption {
        max-width: 90%;
    }

    .banner_img, .banner__container, .top-banner{
        max-height: 50vh;
        min-height: 50vh;
    }
}