li{
    display: flex;
    list-style: none;
    margin-bottom: 0.5rem;
}

.section__contacto form [class*="col-"] {
    padding: 0 10px 20px;
}

.section__contacto p, .section__contacto a{
    font-size: 0.75rem;
    color: grey;
}

.section__contacto a:hover{
    opacity: 0.7;
}

.input__form {
    width: 100%;
    height: 48px;
    font-size: 0.7rem;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    -moz-appearance: none;
    appearance: none;    
    box-shadow: none !important;
}

textarea.input__form {
    height: 5rem;
    font-size: 0.7rem;
    padding: 6px 12px;
}

.contacto__icon{
    color: var(--second-color);
}

.social__icon{
    color: var(--first-color);
}

.social__icon:hover{
    opacity: 0.5;
}

.form__container{
    max-width: 1000px;
}
/* 
.contacto__icon {
    filter:drop-shadow(2px 2px 1px var(--first-color))
}

.social__icon {
    filter:drop-shadow(2px 2px 1px var(--second-color))
} */