.top-banner {
    display: block;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    height: 100%;
}

/* @media screen and (min-width: 960px){ 
    .top-banner{
    background-image: none!important;
    }
} */


@media screen and (max-width: 768px) {
    .banner__carousel {
        display: none !important;
        visibility: hidden !important;
        z-index: -100 !important;
    }

    .top-banner {
        margin-bottom: 0;
    }
}