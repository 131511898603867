.item {
    min-width: 200px;
    padding: 10px;
}

.logoWrap {
    display: block;
    padding: 15px 20px;
}

.logoWrap img{
    object-fit: contain;
}

.carousel__container .owl-carousel .owl-item{
    background-color: var(--first-color);
}

.carousel__container .owl-carousel .owl-item img{
    object-fit: contain;
    width: 200px;
    filter: brightness(0) invert(1);
    /* filter: grayscale(1); */
}

@media screen and (max-width: 478px){
    .carousel__container .owl-carousel .owl-item img, .item{
        width: 150px;
    }
}