.club__container p, .club__container label{
    font-size: 14px;
}

.club__container .about {
    padding: 0;
}

.presidentes__container {
    max-width: 100vw;
    max-height: 45vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.presidentes__container>* {
    flex: 1 1 30px;
    margin-bottom: 0rem;
}

.presidentes__container p {
    margin-bottom: 0.25rem;
}

.expresidente__container{
    min-width: 320px;
    text-align: start;
}

.vocales__column *{
    min-width: 270px;
}

.socios__link {
    font-size: 14px;
    color: darkblue;
}

.socios__link:hover{
    opacity: 0.7;
}


@media screen and (max-width: 991px) {
    .club__container .about {
        padding: 0 !important;
    }
}


@media screen and (max-width: 768px) {
    .vocales__container div{   
        min-width: 50vw;
    }
}

@media screen and (max-width: 478px) {
    .vocales__container div{   
        min-width: 80vw;
        align-items: start!important;
        margin: auto;
        text-align: center;
    }
    
    .expresidente__container{
        min-width: 200px;
        text-align: start;
    }
}