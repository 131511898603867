:root{
  --first-color: #323b60;
  --second-color: #a70c33;
  --third-color: #d696a8;
  --fourth-color: #115778;
  --fifth-color: #7b7b84;
  --background-color: #FFFFFF;

  --filter-second-color: invert(27%) sepia(76%) saturate(1612%) hue-rotate(314deg) brightness(88%) contrast(89%);
  --filter-third-color: invert(76%) sepia(11%) saturate(1071%) hue-rotate(296deg) brightness(84%) contrast(98%);
  --filter-fourth-color: invert(17%) sepia(36%) saturate(5007%) hue-rotate(181deg) brightness(100%) contrast(87%);
  --filter-fifth-color: invert(51%) sepia(7%) saturate(387%) hue-rotate(202deg) brightness(93%) contrast(85%);
  --filter-grey-color: invert(28%) sepia(6%) saturate(1276%) hue-rotate(37deg) brightness(97%) contrast(88%);

  --font-primary: 'Montserrat', sans-serif;
  --font-secondary: 'Inter', sans-serif;
  --font-third: 'Inter', sans-serif;
  --font-nova: ''
}

body{  
  margin: 0;
  background-color: #2d2d2d;
  font-family: 'Montserrat', sans-serif!important;
  min-height: 100vh;
  height: 100%;
}

a:hover{
  cursor: pointer;
}

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row{
  margin: 0!important;
  padding: 0!important;
}

.main__height{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: fadein 2s;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  padding: 2rem;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  /* background-color: rgba(0,0,0,0.8); */
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #000;    
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 1rem!important;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  gap: 1rem;
}

/* .button__primary{
  background-color: #315b38;
  color: white;
  border: 0;
  padding: 8px 20px;
  border-radius: 2rem;
  font-size: 0.75rem;
}

.button__primary:hover{
  background-color: #010101;
  box-shadow: 2px 3px 5px rgba(0,0,0,0.5);
} */


.button__primary{
  color: var(--first-color);  
  font-weight: 400;
  background-color: #FFF;
  padding: 0.3rem 1rem;
  border: 1px solid black!important;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  transition: 0.5s background-color;
}

.button__primary:hover{
  background-color: #000;
  color: #FFF;
  opacity: 1;
}

.button__secondary{
  color: #FFF;  
  font-weight: 400;
  background-color: var(--first-color);
  padding: 0.3rem 1rem;
  border: 0;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  transition: 0.3s background-color;
}

.button__secondary:hover{
  background-color: var(--second-color);
  color: #FFF;
  opacity: 1;
}

@keyframes fadein {
  from { opacity: 0;}
  to   { opacity: 1}
}


@keyframes maxHeightToMin {
  from { min-height: 100vh;}
  to   { min-height: auto;}
}



@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}