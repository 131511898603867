.div__title{    
    background-color: var(--first-color);
    padding: 2rem;
}

.div__title form{
    max-height: 100%;
}

@media screen and (max-width: 479px){
    .div__title form{
        padding: 0 1rem;
    }
}