.deportes__container {
    position: relative;
    padding: 3rem;
    padding-left: 3rem!important;
    padding-right: 3rem!important;
}

.deportes__img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    padding: 0 !important;
    margin: 0 !important;
    object-fit: cover;
    min-height: 70vh;
}


.section-bg-overlay {
    max-height: 75vh;
    background-color: rgba(0, 0, 0, 0.0);
}

.deportes__caption {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
    text-align: start;
    align-items: start;
    justify-content: center;
    color: var(--light-color);
    color: white;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}


.deportes__caption h2 {
    font-size: clamp(12px, 4vw, 32px);
    color: var(--light-color);
    text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.8);
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
}

.deportes__img:hover, .deportes__container:hover .deportes__img{
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
}

.deportes__caption h2:hover{
    cursor: pointer;
}

@media screen and (max-width: 999px) {
    .deportes__container {
        padding: 1rem;
    }
}

@media screen and (max-width: 479px) {    
    .deportes__container {
        padding: 0;
        padding-left: 0!important;
        padding-right: 0!important;
        margin-bottom: 1rem;        
    }

    .deportes__img{
        min-height: 50vh;
    }

    .deportes__caption h2 {
        font-size: 2rem;
    }
}