.container-fluid{
    max-width: 1400px;
}

.header__logo{
    filter: drop-shadow(1px 1px 1px #000);
    border: 0;
}

.header__logo:focus{
    box-shadow: 0 0 0 transparent;
}

.header__logo:hover{
    opacity: 0.7;
}

a.navbar-toggler{
    height: 50px;
}

.navbar-nav{
    width: 100%;
    justify-content: space-around;
}

#navbarNav{
    border: 0;
}

.navbar-nav li{
    min-height: 3rem;
}

.navbar-brand{
    margin-right: 0!important;
}

.navbar a:hover, button:hover{
    color: var(--second-color);
}

.header__link{
    color: #FFF;
    font-weight: 600;
    font-size: 1rem;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.7);
    text-decoration: none;
}

.nav-link:hover{
    color: var(--second-color);
}

.nav-link:focus{
    color: var(--fifth-color);
}

.navbar__bg{
    position: fixed!important;
    top: 0;
    width: 100%;
    z-index: 300;
    background-color: var(--first-color);
    padding: 0 3rem;  
    min-height: 50px;
}

.navbar__blur{    
    backdrop-filter: blur(4px);
}


@media screen and (max-width: 1280px){ 
    .navbar__bg{
        padding: 0;    
    }
}
 
@media screen and (max-width: 1200px){    
    .navbar-nav{
        padding: 1rem 0;
    }

    .navbar-nav div{
        flex-direction: column;
        gap: 0rem!important;
        padding: 0!important;
    }
}
