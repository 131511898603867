.fixture__container .owl-item{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #000;
    width: 100%;
}

.fixture__container .owl-carousel .owl-item img{
    object-fit: fill;
    width: 100%;
    height: 70vh;
    object-position: center;
    align-self: center;
}

.fixture__container, .fixture__img{
    max-height: 70vh;
    object-fit:initial;
    object-position: center;
}

.fixture__carousel{
    width: 20vw;
}

.nav__carousel{
    background-color: rgba(0, 0, 0, 0.5);
    color: #FFF;    
}

.nav__carousel:hover{
    background-color: rgba(0, 0, 0, 0.9);
    color: #DDD;
}

@media screen and (max-width: 821px){
    .fixture__carousel{
        width: 100%;
    }
}